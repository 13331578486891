<template>
  <div class="account-slide-bar">
    <div class="logo-box">
      <router-link :to="`${'/' + this.GLOBAL.currentLanguage + '/'}`">
        <img alt="" src="../assets/image/logo.svg" class="logo" />
      </router-link>
      <div class="separator-line"></div>
      <div class="title">
        {{ $t("account") }}
      </div>
    </div>
    <div class="menu_list">
      <router-link
        v-for="(menu, menuIndex) in menuList"
        :key="menuIndex"
        :to="`${'/' + GLOBAL.currentLanguage + menu.href}`"
      >
        <div
          class="menu btn-cursor"
          :class="{ 'active-menu': currentMenuIndex === menuIndex }"
        >
          <img alt="" :src="menu.icon" class="menu-icon" />
          <div class="menu-title">{{ $t(menu.title) }}</div>
        </div>
      </router-link>
    </div>
    <div class="menu log-out btn-cursor">
      <img
        alt=""
        src="../assets/image/account_slide_bar_menu_logout.svg"
        class="menu-icon"
      />
      <div class="menu-title" @click="logout()">
        {{ $t("account_log_out") }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AccountSlideBar",
  data() {
    return {
      currentMenuIndex: 0,
      menuList: [
        {
          icon: require("../assets/image/account_slide_bar_menu_account.svg"),
          title: "account",
          href: "/account",
        },
        {
          icon: require("../assets/image/account_slide_bar_menu_billing.svg"),
          title: "account_billing",
          href: "/account/billing",
        },
        {
          icon: require("../assets/image/account_slide_bar_menu_invite.svg"),
          title: "account_invite_a_friend",
          href: "/account/invite",
        },
      ],
    };
  },
  computed: {},
  created() {
    const currentRoutePath = this.$route.path.replace(
      "/" + this.GLOBAL.currentLanguage,
      ""
    );
    this.menuList.forEach((menu, index) => {
      if (menu.href === currentRoutePath) {
        this.currentMenuIndex = index;
      }
    });
    if (!this.checkAuth()) {
      this.$router.push("/" + this.GLOBAL.currentLanguage + "/login");
    }
  },
  methods: {
    logout() {
      this.$axios.delete("/auth/logout").then(() => {
        this.$storage.remove("jwt-token");
        this.$router.push("/" + this.GLOBAL.currentLanguage + "/login");
      });
    },
  },
};
</script>

<style scoped>
.account-slide-bar {
  height: 100%;
  width: 230px;
  background: #ffffff;
  position: fixed;
  left: 0;
  top: 0;
}

@media screen and (max-width: 1024px) {
  .account-slide-bar {
    width: 65px;
  }
}

.logo-box {
  height: 75px;
  background: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 27px;
}

.logo {
  width: 93px;
  height: 25px;
}

@media screen and (max-width: 1024px) {
  .logo {
    display: none;
  }
}

.separator-line {
  width: 0;
  height: 8px;
  border: 1px solid #a8a8a8;
}

@media screen and (max-width: 1024px) {
  .separator-line {
    display: none;
  }
}

.title {
  font-size: 16px;
  font-weight: 300;
  color: #707070;
}

@media screen and (max-width: 1024px) {
  .title {
    display: none;
  }
}

.menu_list {
  margin-top: 28px;
}

.menu {
  height: 50px;
  background: #ffffff;
  display: flex;
  align-items: center;
  color: #707070;
  padding-left: 22px;
  width: 100%;
  box-sizing: border-box;
}

.log-out {
  position: absolute;
  bottom: 50px;
  left: 0;
}

.active-menu {
  background: rgba(78, 190, 134, 0.15);
  color: #1d1d1d;
}

.menu-icon {
  width: 20px;
  height: 20px;
}

.menu-title {
  margin-left: 22px;
  font-size: 16px;
  font-weight: 300;
  line-height: 50px;
  opacity: 1;
}

@media screen and (max-width: 1024px) {
  .menu-title {
    display: none;
  }
}

.menu:hover {
  background: rgba(78, 190, 134, 0.15);
  color: #1d1d1d;
}
</style>
