<template>
  <div class="account">
    <AccountSlideBar></AccountSlideBar>
    <div class="account-box">
      <div class="title">
        {{ $t("account") }}
      </div>
      <div class="content">
        <div class="content-row">
          <div class="content-row-left">
            <div class="content-title">
              {{ $t("account_email") }}
            </div>
            <div class="content-info">
              {{ userInfo.email }}
            </div>
          </div>
          <div class="content-row-right">
            <router-link
              :to="`${'/' + this.GLOBAL.currentLanguage + '/change_password'}`"
            >
              <div class="change-password-btn btn-cursor">
                {{ $t("account_change_password") }}
              </div>
            </router-link>
          </div>
        </div>
        <div class="content-row-separator"></div>
        <div class="content-row" v-if="!isExpired">
          <div class="expire-info">
            {{ $t("account_will_expired", { expiredAt: expiredAtDisplay }) }}
          </div>
          <router-link
            :to="`${'/' + this.GLOBAL.currentLanguage + '/pricing'}`"
          >
            <button
              type="button"
              class="expire-row-btn btn-radius-format-1 btn-color-1 btn-cursor"
            >
              <span>{{ $t("account_renewal") }}</span>
            </button>
          </router-link>
        </div>
        <div class="content-row" v-if="isExpired">
          <div class="expire-info has-expired">
            {{ $t("account_expired", { expiredAt: expiredAtDisplay }) }}
          </div>
          <router-link
            :to="`${'/' + this.GLOBAL.currentLanguage + '/pricing'}`"
          >
            <button
              type="button"
              class="expire-row-btn btn-radius-format-1 btn-color-1 btn-cursor"
            >
              {{ $t("account_upgrade") }}
            </button>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AccountSlideBar from "../../components/AccountSlideBar.vue";

export default {
  name: "Account",
  components: { AccountSlideBar },
  data() {
    return {
      userInfo: {
        email: "example@mail.com",
        expiredAt: new Date("12/01/2021 18:30:35").getTime() / 1000,
      },
    };
  },
  computed: {
    isExpired() {
      return (
        parseInt(this.userInfo.expiredAt) <=
        parseInt(Date.parse(new Date()) / 1000)
      );
    },
    expiredAtDisplay() {
      return this.GLOBAL.formatDateByTimestamp(
        this.userInfo.expiredAt,
        "m/d/Y h:i:s"
      );
    },
  },
  methods: {
    getUserInfo() {
      this.$axios.get("/auth/user-info").then((response) => {
        this.$storage.set("user-info", response, 60 * 10);
        this.assignmentResponse(response);
      });
    },
    assignmentResponse(response) {
      this.userInfo.email = response.data.email;
      this.userInfo.expiredAt = response.data.time.expiredAt;
    },
  },
  created() {
    let userInfo = this.$storage.get("user-info");
    if (userInfo === null) {
      this.getUserInfo();
    } else {
      this.assignmentResponse(userInfo);
    }
  },
};
</script>

<style scoped>
.account {
  min-height: 100vh;
  padding-left: 230px;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  background-color: #f8f8f8;
  overflow: hidden;
}

@media screen and (max-width: 1024px) {
  .account {
    padding-left: 65px;
  }
}

.account-box {
  margin-top: 75px;
  padding: 0 16px;
}

.title {
  font-size: 30px;
  font-weight: bold;
  line-height: 37px;
  color: #1d1d1d;
}

.content {
  max-width: 1100px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #ffffff;
  border: 1px solid #e9e9e9;
  box-shadow: 0 0 5px rgba(39, 55, 61, 0.05);
  border-radius: 4px;
  margin-top: 32px;
}

.content-row {
  min-height: 90px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.content-row-left {
  margin-left: 40px;
}

.content-title {
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  color: #1d1d1d;
  margin-top: 21px;
}

.content-info {
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  color: #707070;
  margin-top: 12px;
}

.content-row-right {
  margin-right: 40px;
}

.content-row-separator {
  width: 100%;
  height: 0;
  border: 1px solid #f2f2f2;
}

.expire-info {
  display: flex;
  align-items: center;
  margin: 16px 40px;
}

.expire-row-btn {
  width: 160px;
  height: 38px;
  margin: 16px 40px;
}

.expire-row-btn span {
  font-size: 16px;
  font-weight: 400;
}

.change-password-btn {
  width: 160px;
  height: 38px;
  margin: 16px 0 16px 40px;
  color: #1d1d1d;
  font-size: 16px;
  font-weight: 400;
  line-height: 38px;
}

.has-expired {
  color: #f26d76;
}
</style>
